import React from "react";
import Img from "gatsby-image";

import styles from "./Slide.module.css";

/**
 * Slider actual slide
 */
const Slide = ({ content, alt, opacity, extension, videoDuration }) => {
  const slideStyles = {
    opacity: opacity,
  };

  return (
    <div className={styles.Slide} style={slideStyles}>
      {["mp4", "webm"].includes(extension) ? (
        /**
         * React does not guarantee that muted attribute will be set
         * in the actual DOM so we use `dangerouslySetInnerHtml` to
         * circumvent this issue.
         * Therefore, play the video between seconds 0 through slide duration
         * in order to save bandwith.
         */
        <div
          className={styles.videoWrapper}
          dangerouslySetInnerHTML={{
            __html: `
            <video autoPlay muted loop playsinline>
              <source src=${content}#t=0,${videoDuration}" type="video/mp4" />
            </video>`,
          }}
        />
      ) : (
        <Img alt={`Portfolio pic ${alt}`} fluid={content} />
      )}
    </div>
  );
};

export default Slide;
