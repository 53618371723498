import React from "react";

import styles from "./Arrows.module.css";

const Arrows = ({ onClick }) => (
  <div
    className={`${styles.Arrows} ${styles.arrowPrev}`}
    onClick={onClick}
    onKeyDown={onClick}
    tabIndex="0"
    role="button"
    aria-pressed="false"
    aria-label="prev slide"
  >
    <div className={styles.arrowNext}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M18.3 12.038l-.9-1-9.4-11-2.4 2 8.5 10-8.5 10 2.4 2 9.4-11 .9-1z" />
      </svg>
    </div>
  </div>
);

export default Arrows;
