import React, { useState, useEffect, useCallback, useRef } from "react";

import Slide from "../Slide/Slide";
import NextArrow from "../Arrows/NextArrow";
import PrevArrow from "../Arrows/PrevArrow";

import styles from "./Slider.module.css";

/**
 * Modulo operation (interpreted as "division remainder")
 *
 * We cannot use plain JS modulo because it doesn't implement "division remainder",
 * but some strange JS interpretation of it.
 */
function mod(n, m) {
  return ((n % m) + m) % m;
}

/**
 * Slider component with black transition between slides
 */
const Slider = ({ slides, alt, extension, slideDuration, blackDuration }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [opacityMultiplier, setOpacityMultiplier] = useState(1);
  const intervalRef = useRef();

  const showNextSlide = useCallback(() => {
    // Set activeIndex to the next slide
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    // Set opacity to 0, then after blackDuration, back to 1
    setOpacityMultiplier(0);
    setTimeout(setOpacityMultiplier, blackDuration, 1);
  }, [blackDuration, slides.length]);

  const showPrevSlide = useCallback(() => {
    // Set activeIndex to the prev slide
    setActiveIndex((prevIndex) => mod(prevIndex - 1, slides.length));
    // Set opacity to 0, then after blackDuration, back to 1
    setOpacityMultiplier(0);
    setTimeout(setOpacityMultiplier, blackDuration, 1);
  }, [blackDuration, slides.length]);

  const startInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(
      showNextSlide,
      slideDuration + blackDuration
    );
  }, [blackDuration, showNextSlide, slideDuration]);

  useEffect(() => {
    startInterval();

    // Cleanup function
    return () => clearInterval(intervalRef.current);
  }, [showNextSlide, slideDuration, blackDuration, startInterval]);

  return (
    <div className={styles.Slider}>
      {slides.map((slide, i) => (
        <Slide
          key={slide + i}
          content={slide}
          extension={extension[i]}
          alt={alt[i]}
          opacity={activeIndex === i ? 1 * opacityMultiplier : 0}
          videoDuration={slideDuration / 1000}
        />
      ))}
      <PrevArrow
        onClick={() => {
          startInterval();
          showPrevSlide();
        }}
      />
      <NextArrow
        onClick={() => {
          startInterval();
          showNextSlide();
        }}
      />
    </div>
  );
};

Slider.defaultProps = {
  slideDuration: 3000,
  blackDuration: 500,
};

export default Slider;
