import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Slider from "../components/Slider/Slider";
import SEO from "../components/seo";

const IndexPage = ({ data }) => {
  const source = data.allStrapiSlides.nodes.map((slide) => {
    if (slide.desktop_slide.childImageSharp) {
      return [
        {
          ...slide.mobile_slide.childImageSharp.fluid,
          media: `(max-width: 767px)`,
        },
        {
          ...slide.desktop_slide.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ];
    } else {
      return slide.desktop_slide.publicURL;
    }
  });

  return (
    <Layout>
      <SEO title="Home" />
      <Slider
        slides={source}
        extension={data.allStrapiSlides.nodes.map(
          (slide) => slide.desktop_slide.extension
        )}
        alt={data.allStrapiSlides.nodes.map((slide, i) => i)}
        slideDuration={3000}
        blackDuration={500}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    allStrapiSlides(sort: { order: DESC, fields: created_at }) {
      nodes {
        desktop_slide {
          publicURL
          extension
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobile_slide {
          publicURL
          extension
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
